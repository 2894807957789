html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 100%;
  height: 100%;
}

.header {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em 0 2em;
  height: 5rem;
  box-sizing: border-box;
  border: 0 solid;
  box-shadow: 0 0 0 1px rgba(35, 38, 59, 0.05),
    0 8px 16px -4px rgba(35, 38, 59, 0.25);
}

input[type="text"] {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 3px;
  padding: 10px;
  margin-top: 5px;
  width: 100%;
}

.react-autosuggest__container {
  width: 100%;
}

.react-autosuggest__suggestions-container {
  border: none;
}

.react-autosuggest__suggestions-container--open {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: none;
  border-radius: 3px;
  width: 100%;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(34, 36, 38, 0.15);
}

.react-autosuggest__suggestion {
  list-style: none;
  width: 100%;
  padding: 8px 10px;
}

.react-autosuggest__suggestions-list {
  padding: 0;
  margin: 0;
}
